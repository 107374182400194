import React from 'react';
import styled from 'styled-components';
import {getOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';

const StaffsOutlet = getOutlet('staffs');
const EXPORT_COLLECTION = 'pay_billing';
const EXPORT_TITLE_DEFAULT = '給付明細匯出';
const EXPORT_FIELDS = [
  {key: 'serial_number', name: '序號'},
  {key: 'name', name: '姓名'},
  {key: 'id_card_number', name: '身分證字號/統編'},
  {key: 'address', name: '地址'},
  {key: 'payoff_date', name: '給付日期'},
  {key: 'pay_type', name: '給付類別'},
  {key: 'content', name: '內容'},
  {key: 'payoff_amount', name: '給付總額'},
  {key: 'tax', name: '扣繳稅額'},
  {key: 'payoff_total', name: '給付淨額'},
  {key: 'note', name: '備註'},
  {key: 'admin_creator', name: '創建者'},
  {key: 'admin_updater', name: '更新者'},
];

function transformQuery(queryParams) {
  const query = {};

  if (
    Array.isArray(queryParams.pay_types) &&
    queryParams.pay_types.length > 0
  ) {
    query.pay_type = {$in: queryParams.pay_types};
  }

  if (!!queryParams.payoff_date_start) {
    query.payoff_date = {
      $gte: queryParams.payoff_date_start,
    };
  }

  if (!!queryParams.payoff_date_end) {
    if (query.payoff_date) {
      query.payoff_date = {
        ...query.payoff_date,
        $lte: queryParams.payoff_date_end,
      };
    } else {
      query.payoff_date = {
        $lte: queryParams.payoff_date_end,
      };
    }
  }

  if (!!queryParams.created_start) {
    query.created = {
      $gte: new Date(queryParams.created_start).getTime(),
    };
  }

  if (!!queryParams.created_end) {
    if (query.created) {
      query.created = {
        ...query.created,
        $lte: new Date(queryParams.created_end).getTime(),
      };
    }

    query.created = {
      $lte: new Date(queryParams.created_end).getTime(),
    };
  }

  return query;
}

function transformProjection() {}

function generateRecordsRows(records) {
  const nextRecords = [];

  records.forEach((r) => {
    nextRecords.push({
      ...r,
    });
  });

  return nextRecords.map((r, index) => {
    const returnValues = EXPORT_FIELDS.map((f) => {
      if (f.key === 'serial_number') {
        return `${index + 1}`;
      } else if (f.key === 'address') {
        const mail = `${r.mail_address_zip_code || ''} ${
          r.mail_address_city || ''
        }${r.mail_address_detail || ''}`;

        const send = `${r.send_address_zip_code || ''} ${
          r.send_address_city || ''
        }${r.send_address_detail || ''}`;

        return `通訊：${send}\n寄送：${mail}`;
      } else if (f.key === 'admin_creator') {
        const targetStaff = StaffsOutlet.getValue().find(
          (s) => s.id === r.admin_creator,
        );

        return `${targetStaff ? targetStaff.name : ''}`;
      } else if (f.key === 'admin_updater') {
        const targetStaff = StaffsOutlet.getValue().find(
          (s) => s.id === r.admin_updater,
        );
        return `${targetStaff ? targetStaff.name : ''}`;
      }

      return r[f.key] || '';
    });

    return returnValues;
  });
}

function AdminPayBillingExport(props) {
  return (
    <Wrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            pay_types: {
              type: 'array',
              title: '類別',
              uniqueItems: true,
              items: {
                type: 'string',
                title: '',
                enum: [
                  '車馬費',
                  '講師費',
                  '捐贈',
                  '稿費',
                  '執行業務報酬',
                  '競技、競賽及機會中獎獎金',
                  '其他',
                ],
              },
            },
            payoff_date_start: {
              type: 'string',
              title: '給付日期（起）',
              format: 'date',
            },
            payoff_date_end: {
              type: 'string',
              title: '給付日期（迄）',
              format: 'date',
            },
            created_start: {
              type: 'string',
              title: '創建日期（起）',
              format: 'date',
            },
            created_end: {
              type: 'string',
              title: '創建日期（迄）',
              format: 'date',
            },
          },
        }}
        uiSchema={{}}
        onSubmit={(formData) => {
          let query = transformQuery(formData);
          let projection = transformProjection();

          let excelProps = {
            collection: EXPORT_COLLECTION,
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            query,
            projection,
            sort: ['payoff_date'],
            recordsToAoa: async (records) => {
              const title = [
                '給付明細',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
              ];
              const header = [
                '序號',
                '姓名',
                '身分證字號/統編',
                '地址',
                '給付日期',
                '給付類別',
                '內容',
                '給付總額',
                '扣繳稅額',
                '給付淨額',
                '備註',
                '創建者',
                '更新者',
              ];
              const rows = generateRecordsRows(records);

              return [title, header, ...rows];
            },
            updateWorkSheet: (ws) => {
              // s = start, r = row, c = col, e = end
              const merge = [{s: {r: 0, c: 0}, e: {r: 0, c: 12}}];
              ws['!merges'] = merge;

              //title
              const titles = [
                'A1',
                'B1',
                'C1',
                'D1',
                'E1',
                'F1',
                'G1',
                'H1',
                'I1',
                'J1',
                'K1',
                'L1',
                'M1',
              ];

              for (let i = 0; i < titles.length; i++) {
                const cell = titles[i];

                if (i === 0) {
                  ws[cell].s = {
                    sz: 18,
                    bold: true,
                    alignment: {
                      horizontal: 'center',
                      vertical: 'center',
                    },
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };

                  continue;
                }

                if (i + 1 === titles.length) {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };

                  continue;
                }

                ws[cell].s = {
                  top: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                };
              }

              //cell style
              const cells = Object.keys(ws);
              const excludeCells = ['!cols', '!merges', '!ref', ...titles];

              for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];

                if (!excludeCells.includes(cell)) {
                  ws[cell].s = {
                    ...ws[cell].s,
                    sz: 12,
                    alignment: {
                      wrapText: true,
                    },
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    bottom: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                }
              }

              //col width
              ws['!cols'] = [
                {wpx: 40},
                {wpx: 72},
                {wpx: 133},
                {wpx: 400},
                {wpx: 88},
                {wpx: 110},
                {wpx: 291},
                {wpx: 80},
                {wpx: 80},
                {wpx: 80},
                {wpx: 110},
                {wpx: 80},
                {wpx: 80},
              ];

              //row height
              ws['!rows'] = [{hpx: 30}];
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminPayBillingExport;

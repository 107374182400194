import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import ExportHelper from '../../TbaExportHelper';
import {message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';

const EXPORT_COLLECTION = 'checkout';
const EXPORT_TITLE_DEFAULT = '會費應收明細匯出';
const EXPORT_FIELDS = [
  {key: 'buyer_name', name: '姓名'},
  {key: 'annual_year', name: '年度'},
  {key: 'name', name: ''},
  {key: 'total', name: '金額'},
];

function transformQuery(formData) {
  const query = {
    $and: [
      {'items.0.product.name': {$regex: '常年會費'}},
      {order_id: {$exists: false}},
    ],
  };

  if (formData.annual_year_start) {
    if (isNaN(formData.annual_year_start)) {
      message.error('開徵年度(起)格式不正確');
      throw new Error();
    }

    query.$and.push({annual_year: {$in: [formData.annual_year_start]}});
  }

  if (formData.annual_year_end) {
    if (isNaN(formData.annual_year_end)) {
      message.error('開徵年度(迄)格式不正確');
      throw new Error();
    }

    if (query.$and[2].annual_year) {
      const firstElem = query.$and[2].annual_year.$in[0];
      const duration = Number(formData.annual_year_end) - Number(firstElem);

      for (let i = 1; i < duration + 1; i++) {
        query.$and[2].annual_year.$in.push(`${Number(firstElem) + i}`);
      }
    } else {
      query.$and.push({annual_year: {$in: [formData.annual_year_end]}});
    }
  }

  return query;
}

function transformProjection() {
  const projection = {
    owner: 1,
    buyer_name: 1,
    buyer_email: 1,
    order_number: 1,
    order_id: 1,
    discount_items: 1,
    total: 1,
    subtotal: 1,
    annual_year: 1,
  };
  return projection;
}

async function fetchRecords(formData, {query, projection}) {
  let returnValue = [];

  let pagedCheckoutJStorageResp = await JStorage.fetchDocuments(
    EXPORT_COLLECTION,
    query,
    null,
    {
      offset: 0,
      limit: 10000,
    },
    projection,
  );

  if (pagedCheckoutJStorageResp.total > 10000) {
    message.info('總回傳超過上限10000筆, 超過部分將不會顯示在excel列表中!');
  }

  return pagedCheckoutJStorageResp.results;
}

function generateRecordsRows(records, helper) {
  const nextRecords = [];

  records.forEach((r) => {
    nextRecords.push({
      owner: r.owner,
      buyer_name: r.buyer_name,
      annual_year: r.annual_year,
      name: '1~12',
      total: r.subtotal,
    });

    if (Array.isArray(r.discount_items) && r.discount_items.length > 0) {
      r.discount_items.forEach((d) => {
        nextRecords.push({
          owner: r.owner,
          buyer_name: '',
          annual_year: '',
          name: `${d.name}`,
          total: -d.amount,
        });
      });

      nextRecords.push({
        owner: r.owner,
        buyer_name: '',
        annual_year: '',
        name: '小計：',
        total: r.total,
      });
    }
  });

  return nextRecords.map((r, index) => {
    const returnValues = EXPORT_FIELDS.map((f) => {
      if (f.key === 'buyer_address') {
        const user = helper.getUserPartialData(r.owner);

        return `${user.mail_address_zip_code || ''} ${
          user.mail_address_city || ''
        }${user.mail_address_detail || ''}`;
      }

      return r[f.key];
    });

    return returnValues;
  });
}

function AdminFeeExport(props) {
  return (
    <Wrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            annual_year_start: {
              type: 'string',
              title: '開徵年度（起）',
            },
            annual_year_end: {
              type: 'string',
              title: '開徵年度（迄）',
            },
          },
        }}
        uiSchema={{}}
        onSubmit={(formData) => {
          let query = transformQuery(formData);
          let projection = transformProjection();

          let excelProps = {
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            fetchRecords: async () =>
              fetchRecords(formData, {query, projection}),
            recordsToAoa: async (records) => {
              const helper = new ExportHelper({
                userIds: records.map((r) => r.owner),
                userProjection: {
                  owner: 1,
                  name: 1,
                  mail_address_city: 1,
                  mail_address_zip_code: 1,
                  mail_address_detail: 1,
                },
              });

              await helper.fetchAll();

              const title = [`會費應收明細`];
              const description = [
                `查詢日期：${new Date().toLocaleDateString(
                  'sv',
                )}\n開徵年度區間：${formData.annual_year_start || '[未填寫]'}-${
                  formData.annual_year_end || '[未填寫]'
                }\n`,
              ];
              const header = ['姓名', '年度', '起迄', '金額'];
              const rows = generateRecordsRows(records, helper);

              return [title, description, header, ...rows];
            },
            updateWorkSheet: (ws) => {
              // s = start, r = row, c = col, e = end
              const merge = [
                {s: {r: 0, c: 0}, e: {r: 0, c: 3}},
                {s: {r: 1, c: 0}, e: {r: 1, c: 3}},
              ];
              ws['!merges'] = merge;
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminFeeExport;

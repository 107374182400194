import React from 'react';
import {message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../../AppActions';

function AdminReceiptConfig(props) {
  const [config, setConfig] = useOutlet('config');

  return (
    <GenericForm
      instance={config}
      schema={{
        title: '',
        type: 'object',
        required: ['chairman', 'stamp_receipt'],
        properties: {
          chairman: {
            type: 'string',
            title: '理事長',
          },
          stamp_receipt: {
            type: 'string',
            title: '收據專用章',
          },
        },
      }}
      uiSchema={{
        stamp_receipt: {
          'ui:widget': 'rev-single-image-uploader',
        },
        stamp_duty: {
          'ui:widget': 'rev-single-image-uploader',
        },
      }}
      onSubmit={async (formData, extValue) => {
        AppActions.setLoading(true);
        const {chairman, stamp_receipt, stamp_duty} = formData;

        try {
          const resp = await JStorage.updateDocument(
            'site',
            {
              id: config.id,
            },
            {chairman, stamp_receipt},
          );

          await JStorage.cacheDocuments(
            'site',
            {},
            null,
            null,
            null,
            undefined,
            {
              key: 'rev-site-cache.json',
            },
          );

          setConfig(resp);
          message.success('更新成功');
        } catch (err) {
          console.warn(err);
          message.error('更新失敗');
        } finally {
          AppActions.setLoading(false);
        }
      }}
    />
  );
}

export default AdminReceiptConfig;
